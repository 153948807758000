import React from 'react';
import { Link } from "react-router-dom";
import breckywed from '../media/Phan_Wheeler-83.jpg';

class Home extends React.Component {

  render() {
    return(
      <div className='main'>
        <div className='save-the-date'>
          <Link to='/photos'>

            <div className="lds-heart">
              <div>
              </div>
            </div>
            <br /><br />
            <div>
              <img class="hover" alt="becky and brandon" src={breckywed}/>
            </div>

            <br/>
            <br/>
            </Link>
        </div>
    </div>
    )
  }
}

export default Home
